html, body{
    margin: 0;
    padding: 0;
    font-family: "roboto", sans-serif !important;
}

::-webkit-scrollbar {
    width: 4px;
    height: 0px;
    -webkit-border-radius: 16px;
  }
  
  ::-webkit-scrollbar-track-piece {
    background-color: #ffffff;
    -webkit-border-radius: 3px;
  }
  
  ::-webkit-scrollbar-thumb:vertical {
    height: 0px;
    background-color: #666;
    -webkit-border-radius: 3px;
  }  

.form-group.custom-form-group{
    color: #F5A623;
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 2rem;
}

.custom-form-group .form-control{
    border-color: #F5A623;
}

.custom-form-group .form-label{
    margin-bottom: 0;
}

.showBackgroundLoading{
  position: fixed; 
  z-index: 999; 
  left: 0;
  top: 0;
  width: 100%; 
  height: 100%; 
  overflow: auto; 
  background-color: rgb(0, 0, 0); 
  background-color: rgba(0, 0, 0, 0.4);
}

.hideBackgroundLoading{
  display: none;
}

._loading_overlay_content {
  top: 45%;
  left: 39.5%;
  position: fixed;
}

@media (max-width: 680px){
  ._loading_overlay_content {
    top: 40%;
    left: 10.5%;
  }
}